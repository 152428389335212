import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore/lite';
import Firebase from "../firebase/firebase";

const database = Firebase.database;

const getProductsList = async () => {
    const productsCol = collection(database, 'products');
    const productsSnapshot = await getDocs(productsCol);
    const productsList = productsSnapshot.docs.map(doc => doc.data());
    return productsList;
}

const getProductsByCategory = async (category) => {
    const productsCol = collection(database, 'products');
    const q = query(productsCol, where("category", "array-contains", category));

    const querySnapshot = await getDocs(q);
    const productsList = querySnapshot.docs.map(doc => {
        return { id: doc.id, ...doc.data() };
    });
    return productsList;
}

const getProductById = async (id) => {
    const productRef = doc(database, "products", id);
    const productSnap = await getDoc(productRef);
    return productSnap.data();
}

const getCategoryList = async () => {
    const сategoriesCol = collection(database, 'сategories');
    const сategoriesSnapshot = await getDocs(сategoriesCol);
    const сategoriesList = сategoriesSnapshot.docs.map(doc => doc.data());
    return сategoriesList;
}

const DataService = {
    getProductsList,
    getProductsByCategory,
    getCategoryList,
    getProductById
};

export default DataService;