import "./navigation.scss";
import { NavLink } from "react-router-dom";

const navigationItems = [
    {
        text: "Главная",
        link: "/",
    },
    {
        text: "Доставка",
        link: "delivery",
    },
    // {
    //     text: "Контакты",
    //     link: "contacts",
    // },
];

const Navigation = () => {

    return (
        <nav className="navigation">
            <ul className="navigation-list">
                {navigationItems.map((item) => {
                    return (
                        <li
                            className="navigation-item"
                            key={item.link.toString()}
                        >
                            <NavLink
                                className="navigation-link"
                                activeClass="active"
                                to={item.link}
                            >
                                {item.text}
                            </NavLink>
                        </li>
                    )
                })}
            </ul>
        </nav>
    )
}

export default Navigation;