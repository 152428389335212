import { collection, addDoc } from 'firebase/firestore/lite';
import Firebase from "../firebase/firebase";
import dbSet from "./bdSet"

const database = Firebase.database;

let flag = false;

const setProduct = async () => {
    if (flag) return
    flag = true;
    console.log("отправилось");
    for (const item of dbSet) {
        await addDoc(collection(database, "products"), {
            description: item.description,
            imgsrc: item.imgsrc,
            category: item.category,
            name: item.name,
            price: item.price
        })
    }
}

const AutoRunService = {
    setProduct
};

export default AutoRunService;