import "./categoryCard.scss";


const CategoryCard = ({ cardInfo }) => {

    return (
        <article>
            <a href={`category/${cardInfo.catName}`}>
                <img src={cardInfo.catImg} alt="фото категории" />
            </a>
            <h3>{cardInfo.catNameRu}</h3>
        </article>
    )
}

export default CategoryCard;