import React, { useRef } from "react";
import useSlider from "../../hooks/useSlider";
import "./slider.scss";

const Slider = ({ images }) => {
    const slideImage = useRef(null)
    const { goToPreviousSlide, goToNextSlide, goToPoint } = useSlider(
        slideImage,
        images
    )

    return (
        <div className="slider" >
            {/* <div className="slider__content"> */}
                <img src="" alt="фото товара" ref={slideImage} />
                {
                    images.length > 1 &&
                    <>
                        <button className="slider__btn slider__btn--left" onClick={goToPreviousSlide} >
                            <img className="" src={require("./icons/arrow3.png")} alt="" />
                        </button>
                        <div className="slider__points">
                            {images.map((image, index) => {
                                return (
                                    <div className="slider__point" onClick={() => goToPoint(index)}></div>
                                )
                            })}
                        </div>
                        <button className="slider__btn slider__btn--right" onClick={goToNextSlide}>
                            <img src={require("./icons/arrow3.png")} alt="" />
                        </button>
                    </>
                }
            {/* </div> */}
        </div>
    )
}

export default Slider