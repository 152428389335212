import "./categoryPage.scss"
import { Helmet } from "react-helmet";
import DataService from "../../core/services/data.service"
import { useEffect, useState, useRef } from "react";
import { useNavigate, Outlet, useParams } from 'react-router-dom';
import { useContext } from "react";
import { Context } from "../../context";
import ProductCard from "../../components/productCard/ProductCard";
import { NavLink } from "react-router-dom";

const pages = {
    "strawberry": {
        name: "Клубника в шоколаде",
        title: "Клубника в шоколаде Воронеж купить",
        description: "Клубника в шоколаде Воронеж. Букеты из клубники в шоколаде. Клубника в шоколаде Воронеж купить. Студия Счастливый момент.",
        keywords: "Клубника в шоколаде Воронеж купить, Букет из клубники в шоколаде воронеж, Клубника в шоколаде доставка, Купить букет из клубники в шоколаде воронеж",
        canonicalHref: "https://xn----7sbgnmhlmali2abok0exd.xn--p1ai/category/strawberry",
        text: "Насладитесь сочетанием свежей клубники и нежнейшего шоколада с нашими букетами из клубники в шоколаде. Эти аппетитные букеты станут идеальным подарком для любителей сладостей. Порадуйте себя или своих близких этим вкусным и оригинальным подарком."
    },
    "dried": {
        name: "Букеты из сухофруктов",
        title: "Букет из сухофруктов",
        description: "Букет из сухофруктов Воронеж. Букеты из сухофрукутов. Букетов из орехов и сухофруктов. Студия Счастливый момент.",
        keywords: "Букет из сухофруктов, Букет из сухофруктов воронеж, Букет из орехов и сухофруктов, Купить букет из сухофруктов воронеж, Купить букет из конфет воронеж",
        canonicalHref: "https://xn----7sbgnmhlmali2abok0exd.xn--p1ai/category/dried",
        text: "Удивите своих близких оригинальным и полезным подарком - букетами из сухофруктов. Наши букеты изготовлены из отборных и свежих сухофруктов, которые не только радуют глаз, но и богаты витаминами и питательными веществами."
    },
    "candy": {
        name: "Букеты из конфет",
        title: "Букет из конфет",
        description: "Купить букет из конфет с доставкой Воронеж. Букеты из конфет. Студия Счастливый момент. Широкий ассортимент конфетных букетов",
        keywords: "Букет из конфет, Конфетный букет, Букет из конфет воронеж, Купить букет из конфет воронеж, Купить букет из конфет воронеж",
        canonicalHref: "https://xn----7sbgnmhlmali2abok0exd.xn--p1ai/category/candy",
        text: "Сделайте особенный подарок с нашими букетами из конфет. Изысканные и стильные конфетные букеты порадуют не только сладкоежек, но и любителей оригинальных подарков. Удивите своих близких этим прекрасным сочетанием вкуса и эстетики."
    },
    "choco": {
        name: "Шоколадные букеты",
        title: "Шоколадный букет",
        description: "Купить шоколадный букет с доставкой Воронеж. Букеты из шоколада. Студия Счастливый момент. Широкий ассортимент шоколадных букетов",
        keywords: "Шоколадный букет, Букет из шоколада, Букет из шоколада воронеж, Купить букет из шоколада воронеж, Купить букет из Шоколада воронеж",
        canonicalHref: "https://xn----7sbgnmhlmali2abok0exd.xn--p1ai/category/choco",
        text: "Завораживающий аромат и нежный вкус - все это в наших букетах из шоколада. Насладитесь роскошью и изысканностью наших шоколадных букетов и сделайте особенный подарок для своих близких. Удивите их этим изысканным и неповторимым подарком."
    },
    "marshmallow": {
        name: "Букеты из зефира",
        title: "Букет из зефира",
        description: "Букеты из зефира Воронеж. Купить зефирный букет с доставкой. Студия Счастливый момент. Широкий ассортимент зефирных букетов",
        keywords: "Букет из зефира, Зефирный букет, Букет из зефира воронеж, Купить букет из зефира воронеж, Купить зефирный букет воронеж",
        canonicalHref: "https://xn----7sbgnmhlmali2abok0exd.xn--p1ai/category/marshmallow",
        text: "Дарите нежность и вкус с нашими зефирными букетами. Изготовленные с любовью и вниманием зефирные букеты станут прекрасным подарком для любого случая. Насладитесь нежным вкусом и оригинальным дизайном наших зефирных букетов."
    },
    "nuts": {
        name: "Букеты из орехов и сухофруктов",
        title: "Букет из орехов",
        description: "Букеты из орехов Воронеж. Букеты из сухофруктов. Букеты из орехов и сухофруктов. Студия Счастливый момент.",
        keywords: "Букет из орехов, Ореховый букет, Букет из орехов и сухофруктов, Купить букет из орехов воронеж, Купить ореховый букет воронеж",
        canonicalHref: "https://xn----7sbgnmhlmali2abok0exd.xn--p1ai/category/nuts",
        text: "Попробуйте наши ореховые букеты и насладитесь сочетанием красоты и вкуса. Изготовленные с использованием только самых свежих и качественных орехов букеты станут прекрасным подарком для любого гурмана. Порадуйте себя или своих близких этим изысканным и полезным подарком."
    },
    "box": {
        name: "Подарочные боксы",
        title: "Подарочные коробки воронеж",
        description: "Подарочные коробки из орехов и сухофруктов. Боксы из сухофруктов и орехов. Букеты из орехов и сухофруктов. Студия Счастливый момент.",
        keywords: "Подарочные коробки, Ореховый букет, Букет из орехов и сухофруктов, Боксы из сухофруктов и орехов, Купить ореховый букет воронеж, Подарочные коробки из орехов и сухофруктов",
        canonicalHref: "https://xn----7sbgnmhlmali2abok0exd.xn--p1ai/category/box",
        text: "Наши подарочные боксы представляют собой изысканные комбинации вкусов и текстур. В каждом боксе вы найдете разнообразие свежих и качественных сухофруктов, таких как изюм, курага, чернослив, а также ассорти орехов, включая миндаль, фундук, кешью и многие другие."
    },
    "mans": {
        name: "Мужские букеты",
        title: "Мужские букеты",
        description: "Мусжкие букеты. Мясные, снековые букеты. Букет на 24 февраля. Букеты к пиву. Букеты из орехов. Студия Счастливый момент.",
        keywords: "Мусжкие букеты. Мясные букеты. Снековые букеты. Букет на 24 февраля. Букеты к пиву. Букеты из орехов. Купить мужской букет воронеж",
        canonicalHref: "https://xn----7sbgnmhlmali2abok0exd.xn--p1ai/category/box",
        text: "Удивите и порадуйте особенного мужчину в своей жизни неповторимым мужским букетом! Наши мужские букеты - это не только оригинальный и вкусный подарок, но и настоящий праздник для гурманов и ценителей качественных продуктов. Закажите сегодня наш мужской букет с мясными деликатесами, сырами или чипсами, и порадуйте своего особенного мужчину неповторимым вкусом и элегантностью!"
    },
}

const CategoryPage = () => {
    const { category } = useParams();

    const [productList, setProductList] = useState([])
    const [allProductsList, setAllProductsList] = useState([])

    const inputRef = useRef(null);
    let navigate = useNavigate();
    useEffect(() => {
        getCategoryList()
        if (category == "strawberry") {
            inputRef.current.checked = true;
        }
    }, [category]);

    const getCategoryList = async () => {
        const data = await DataService.getProductsByCategory(category)
        // console.log(data);
        setProductList(data);
        setAllProductsList(data);
    };

    let { currentCard, toggleCurrentCard } = useContext(Context);

    const clickOnCard = (card) => {
        navigate(`${card.id}`);
        toggleCurrentCard(card);
    }

    const changeCheckbox = (e) => {
        if (!e.checked) {
            const filteredProducts = productList.filter(item => {
                return item.noFlowers
            })
            setProductList(filteredProducts)
        } else {
            setProductList(allProductsList)
        }
    }

    return (
        <>
            <Helmet>
                <title>{pages[category]?.title}</title>
                <meta name="description" content={pages[category]?.description} />
                <meta name="keywords" content={pages[category]?.keywords} />
                <link rel="canonical" href={pages[category]?.canonicalHref} />
            </Helmet>
            <Outlet />
            <div className="category-page content-cnt">
                <h1 className="page__title">{pages[category]?.name}</h1>
                {
                    category == "strawberry" &&
                    <div className="filters">
                        <label
                            className="filters__label"
                            htmlFor="strawberry"
                        >
                            Цветы в составе
                        </label>
                        <input
                            className="filters__input"
                            id="strawberry"
                            onChange={e => changeCheckbox(e.target)}
                            ref={inputRef}
                            type="checkbox"
                        />
                    </div>
                }

                <NavLink
                    className="category-page__back-btn"
                    to="/"
                >
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                            <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M9.00002 15.3802H13.92C15.62 15.3802 17 14.0002 17 12.3002C17 10.6002 15.62 9.22021 13.92 9.22021H7.15002" stroke="#000000" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M8.57 10.7701L7 9.19012L8.57 7.62012" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        </g>
                    </svg>
                </NavLink>
                <div className="category-page-content">
                    {productList.map((item) => {
                        return (
                            <ProductCard
                                onClick={(value) => {
                                    clickOnCard(value);
                                }}
                                cardInfo={item}
                            />
                        )
                    })}
                </div>
                <div className="category-page__text page__text">
                    <p>{pages[category]?.text}</p>
                </div>
            </div>
        </>
    )
}

export default CategoryPage;