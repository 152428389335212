import "./productPage.scss";
import Slider from "../../components/slider/Slider";
import { useEffect, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Context } from "../../context";
import DataService from "../../core/services/data.service"

const ProductPage = () => {
    const { category, id } = useParams();
    let { currentCard, toggleCurrentCard } = useContext(Context);
    const [cardInfo, setCardInfo] = useState(currentCard)
    const [isDirectLink, setIsDirectLink] = useState(false);

    const [isMobile, setIsMobile] = useState(false)
    let navigate = useNavigate();
    let scrollTo = window.scrollY;

    const location = useLocation();
    const currentPath = location.pathname;

    useEffect(() => {

        const getProduct = async (id) => {
            const data = await DataService.getProductById(id);
            setCardInfo(data);
        };

        if (!cardInfo || cardInfo.id !== id) {
            getProduct(id);
            setIsDirectLink(true);
        }

        document.querySelector('body').style.position = "fixed";
        const handleResize = () => {
            if (window.innerWidth < 1150) {
                setIsMobile(true);
                if (document.querySelector(".close-button")) {
                    if (window.innerWidth < 760) {
                        document.querySelector(".close-button").style.position = "fixed";
                    } else {
                        document.querySelector(".close-button").style.position = "absolute";
                    }
                }
            } else {
                setIsMobile(false);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const closeModal = () => {
        if (!isDirectLink) {
            navigate(-1);
        } else {
            navigate(`/category/${category}`);
        }
        document.querySelector('body').style.position = "static";
        window.scrollTo({
            top: scrollTo,
        })
    }
    return (
        <>
            {cardInfo ? (
                <div
                    className="modal"
                    onClick={closeModal}
                >
                    <Helmet>
                        <title>{cardInfo.name}</title>
                        <meta name="description" content={cardInfo.description} />
                        <meta property="og:type" content="website" />
                        <meta property="og:url" content={`https://xn----7sbgnmhlmali2abok0exd.xn--p1ai${location.pathname}`} />
                        <meta property="og:title" content={cardInfo.name} />
                        <meta property="og:description" content={cardInfo.description} />
                        <meta property="og:image" content={cardInfo.imgsrc[0]} />
                    </Helmet>
                    <div
                        className="modal__content"
                        onClick={e => e.stopPropagation()}
                    >
                        <div
                            className="close-button"
                            onClick={closeModal}
                        >
                        </div>
                        <div className="modal__desktop-img">
                            {
                                !isMobile &&
                                <Slider images={cardInfo.imgsrc} />
                            }

                        </div>
                        <div className="modal__description-block">
                            <div className="modal__order">
                                <p>Заказать: </p>
                                <a className="modal__social-media-item" target="_blank" href="https://t.me/lanasventana">
                                    <img src={require("./icons/tg.webp")} alt="telegram" />
                                </a>
                                <a className="modal__social-media-item" target="_blank" href="https://wa.me/+79802321143">
                                    <img src={require("./icons/wa.webp")} alt="whatsapp" />
                                </a>
                                <a className="modal__social-media-item" target="_blank" href="https://vk.com/write-216213173">
                                    <img src={require("./icons/vk.webp")} alt="vkontakte" />
                                </a>
                            </div>
                            <h2 className="modal__name">{cardInfo.name}</h2>
                            <p className="modal__price">Цена: {cardInfo.price}₽</p>
                            <div className="modal__mobile-img">
                                {
                                    isMobile &&
                                    <Slider images={cardInfo.imgsrc} />
                                }
                            </div>
                            <p className="modal__description text-indent">{cardInfo.description}</p>
                            {/* <p className="modal__delivery no-margin">
                            Время получения – от 90 минут.
                        </p> */}
                            <p className="modal__delivery no-margin text-indent">
                                Стоимость доставки по городу – от 100₽, доставка за пределы города рассчитывается индивидуально. Время получения – от 90 минут.
                            </p>
                        </div>
                    </div>
                </div >
            ) : (
                <div className="modal__loading">
                    <p className="modal__loading-text">Загрузка...</p>
                </div>
            )}
        </>
    )
}

export default ProductPage;