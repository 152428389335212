import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore/lite';

const firebaseConfig = {
    projectId: "happymoment-2491e",
};

const app = initializeApp(firebaseConfig);
const database = getFirestore(app);   

const Firebase = {
    database
}   

export default Firebase;