import "./menu.scss";
import { NavLink } from "react-router-dom";
import React, { useState } from 'react';

const menuItems = [
    {
        text: "НА ГЛАВНУЮ",
        link: "/",
    },
    {
        text: "ЗЕФИРНЫЕ БУКЕТЫ",
        link: "/category/marshmallow",
    },
    {
        text: "ПОДАРОЧНЫЕ БОКСЫ",
        link: "/category/box",
    },
    {
        text: "БУКЕТЫ ИЗ СУХОФРУКТОВ",
        link: "/category/dried",
    },
    {
        text: "КЛУБНИКА В ШОКОЛАДЕ",
        link: "/category/strawberry",
    },
    {
        text: "БУКЕТЫ ИЗ КОНФЕТ",
        link: "/category/candy",
    },
    {
        text: "ОРЕХОВЫЕ БУКЕТЫ",
        link: "/category/nuts",
    },
    {
        text: "МУЖСКИЕ БУКЕТЫ",
        link: "/category/mans",
    },
    {
        text: "БУКЕТЫ ИЗ ШОКОЛАДА",
        link: "/category/choco",
    },
    {
        text: "ДОСТАВКА",
        link: "delivery",
    },
    // {
    //     text: "Контакты",
    //     link: "contacts",
    // },
];

const Menu = () => {

    const [active, setActive] = useState(false);

    return (
        <div className={active ? "menu-cnt active" : "menu-cnt"}>
            <div
                className="menu-cnt__background"
                onClick={() => setActive(false)}
            >

            </div>
            <div className="menu">
                <span
                    className="menu__menu-btn"
                    onClick={() => setActive(!active)}
                >
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                            <path d="M4 6H20M4 12H20M4 18H20" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                        </g>
                    </svg>
                </span>
                <nav className="menu__nav">
                    <ul className="menu__list">
                        {menuItems.map((item) => {
                            return (
                                <li className="menu__item">
                                    <NavLink
                                        className="menu__link"
                                        activeClass="active"
                                        to={item.link}
                                        onClick={() => setActive(!active)}
                                    >
                                        {item.text}
                                    </NavLink>
                                </li>
                            )
                        })}
                    </ul>
                </nav>
            </div>
        </div>
    )
}

export default Menu;