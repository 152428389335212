import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import MainPage from './pages/mainPage/MainPage';
import DeliveryPage from './pages/deliveryPage/DeliveryPage';
import CategoryPage from './pages/categoryPage/CategoryPage';
import ProductPage from './pages/productPage/ProductPage';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App />}>
                    <Route index element={<MainPage />} />
                    <Route path="/category/:category" element={<CategoryPage />}>
                        <Route path=":id" element={<ProductPage />} />
                    </Route>
                    <Route path="delivery" element={<DeliveryPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);

reportWebVitals();

