import { Outlet } from "react-router-dom";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Modal from "./pages/productPage/ProductPage";
import Menu from "./components/menu/Menu";
import { Context } from "./context";
import { createContext, useContext, useEffect, useState } from "react";
import { Route, useLocation } from 'react-router-dom';

import "./App.scss"
import Slider from "./components/slider/Slider";
import SocialMedia from "./components/socialMedia/SocialMedia";
const MyContext = createContext();
export const Provider = MyContext.Provider;

function App() {

    const location = useLocation();

    useEffect(() => {
        if (!window.location.href.includes("localhost")) {
            window.ym(96633334, "hit", window.location.href)
        }
    }, [location]);

    const [currentCard, setCurrentCard] = useState(null);

    const toggleCurrentCard = (card) => {
        setCurrentCard(card)
    }
    // const [modalActive, setmodalActive] = useState(true);
    // const [scrollTo, setscrollTo] = useState(0);

    return (
        <Context.Provider value={{ currentCard, toggleCurrentCard }}>
            <Header />
            <main className="main-content cnt">
                <div className="main-content__background"></div>
                <Outlet />
            </main>
            <Menu />
            <SocialMedia type="mobile"/>
        </Context.Provider>
    );
}

export default App;
