import { useEffect } from 'react'

const useSlider = (slideImage, images) => {
    let slideCounter = 0;

    useEffect(() => startSlider())

    const togglePoint = (position) => {
        let sliderPoints
        if (document.querySelector(".slider__points")) {
            sliderPoints = document.querySelector(".slider__points").childNodes;
        } else {
            return
        }
        
        sliderPoints.forEach(sliderPoint => {
            sliderPoint.classList.remove("active")
        })
        if (images.length > 1) {
            sliderPoints[position].classList.add("active")
        }
        slideCounter = position
    }

    const startSlider = () => {
        slideImage.current.src = `${images[0]}`;
        togglePoint(0)
    }

    const handleSlide = slide => {
        slideImage.current.src = `${images[slide]}`;
        togglePoint(slide)
    }

    const goToPoint = (index) => {
        handleSlide(index)
    }

    const goToPreviousSlide = () => {
        if (slideCounter === 0) {
            handleSlide(images.length - 1)
            slideCounter = images.length - 1;
            return
        }
        slideImage.current.src = `${images[slideCounter - 1]}`;
        slideCounter--;
        togglePoint(slideCounter)
    }

    const goToNextSlide = () => {
        if (slideCounter === images.length - 1) {
            startSlider()
            slideCounter = -1;
        }

        slideImage.current.src = `${images[slideCounter + 1]}`;
        slideCounter++;
        togglePoint(slideCounter)
    }

    return { goToPreviousSlide, goToNextSlide, goToPoint }
}

export default useSlider