import React from "react";
import "./socialMedia.scss";

const SocialMedia = ({ type }) => {
    return (
        <div className={`social-media ${type}`} >
            <a className="social-media__item" target="_blank" href="https://t.me/lanasventana">
                <img className="social-media__item-img" src={require("./icons/tg.webp")} alt="telegram" />
            </a>
            <a className="social-media__item" target="_blank" href="https://wa.me/+79802321143">
                <img className="social-media__item-img" src={require("./icons/wa.webp")} alt="whatsapp" />
            </a>
            <a className="social-media__item" target="_blank" href="https://vk.com/write-216213173">
                <img className="social-media__item-img" src={require("./icons/vk.webp")} alt="vkontakte" />
            </a>
        </div>
    )
}

export default SocialMedia