import "./deliveryPage.scss"
import { Helmet } from "react-helmet";

const DeliveryPage = () => {
    return (
        <>
            <Helmet>
                <title>Доставка - Счастливый момент</title>
                <meta name="description" content="Ознакомиться с правилами доставки. Доставка от 100 рублей Воронежу. За пределы города стоимость доставки обсуждается при заказе. Возможна круглосуточная доставка по предварительной договоренности." />
                <link rel="canonical" href="https://xn----7sbgnmhlmali2abok0exd.xn--p1ai/delivery"/>
            </Helmet>
            <div className="delivery-page content-cnt">
                <h1 className="page__title">Правила доставки</h1>
                <ul>
                    <li>
                        <p className="page__text">Вы можете забрать товар самовывозом или заказать доставку.</p>
                    </li>
                    <li>
                        <p className="page__text">Доставка в пределах города Воронеж – от 100₽.</p>
                    </li>
                    <li>
                        <p className="page__text">Доставка за пределы города рассчитывается индивидуально.</p>
                    </li>
                    <li>
                        <p className="page__text">Возможна круглосуточная доставка по предварительной договоренности.</p>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default DeliveryPage;