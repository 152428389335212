import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import DataService from "../../core/services/data.service"
import { useNavigate } from "react-router-dom";
import "./mainPage.scss";
import Menu from "../../components/menu/Menu";
import CategoryCard from "../../components/categoryCard/CategoryCard";
import AutoRunService from "../../core/autoRun/autoRun";

const MainPage = () => {
    const navigate = useNavigate();
    const [categoryList, setCategoryList] = useState([])

    useEffect(() => {
        getCategoryList()
    }, []);

    const getCategoryList = async () => {
        const data = await DataService.getCategoryList()
        // console.log(data);
        setCategoryList(data);
    };

    return (
        <>
            <Helmet>
                <title>Съедобный букет Воронеж - Счастливый момент</title>
                <meta name="description" content="Заказать съедобные букеты и цветы Воронеж с доставкой. Букет из клубники, шоколадный букет, букет из фруктов, букет из зефира, букет из конфет, шоколадные букеты. Мужской букет. Цветочные корзины и коробки." />
                <meta name="keywords" content="съедобный букет, съедобные букеты воронеж, букет из конфет, букет из фруктов, букет из зефира, фруктовый букет, букет из орехов, мужской букет" />
            </Helmet>
            <div className="main content-cnt">
            {/* <button onClick={AutoRunService.setProduct}>ОТПРАВИТЬ</button> */}
                <section>
                    <header>
                        <h1 className="main__title page__title">
                            Съедобные букеты
                        </h1>
                    </header>

                    <div className="main__categories">
                        {categoryList.map((item) => {
                            return (
                                <CategoryCard
                                    cardInfo={item}
                                />
                            )
                        })}
                    </div>

                    <div className="main__text">
                    </div>
                </section>
            </div>
        </>
    )
}

export default MainPage;