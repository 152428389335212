import "./productCard.scss";

const ProductCard = ({ onClick, cardInfo }) => {
    return (
        <div onClick={() => onClick(cardInfo)} className="card">
            <img className="card__img" src={cardInfo.imgsrc[0]} alt="" />
            <div className="card__text-block">
                <span className="card__price card__text">Цена: {cardInfo.price}₽</span>
                <span className="card__name card__text">{cardInfo.name}</span>
            </div>
        </div>
    )
}

export default ProductCard;